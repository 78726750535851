<template>
  <main>
    <div>
      <div class="wrapper mb-8">
        <ul class="flex-col mt-12 text-xs">
          <li>Settings</li>
          <li class="font-bold mt-4 text-lg">Integrations</li>
        </ul>
      </div>

      <trac-modal
        :cancel="true"
        @close="integrationViewModalCtrl = false"
        v-if="integrationViewModalCtrl"
      >
        <div class="flex flex-col items-center my-6">
          <div
            class="
              big-shadow
              rounded-lg
              w-32
              font-bold
              p-6
              flex flex-col
              items-center
              mx-2
            "
          >
            <div class="rounded-sm">
              <img :src="selectedItem.imgUrl" alt="" srcset="" />
            </div>
            <p class="mt-2 text-xs">{{ selectedItem.name }}</p>
          </div>

          <h3 class="font-bold mt-12">About Quickbooks</h3>
          <p class="text-center text-gray-800 mt-4 mx-6 text-xs">
            Quickbooks is an online accounting software for small businesses to
            accept business payments, manage and pay bills, and payroll
            functions.
          </p>

          <h3 class="font-bold mt-12">How the integration works?</h3>
          <p class="text-center text-gray-800 mt-4 mx-6 text-xs">
            Traction is the heart of your online business, and with so much data
            coming through, you need a way to stay organized and keep your books
            up to date. When you integrate Traction with QuickBooks, your
            inventory, orders, customers and shipping are automatically updated
            and accurate.
          </p>

          <div class="mt-10">
            <trac-button @button-clicked="openIntegrationConnection"
              >Proceed</trac-button
            >
          </div>
        </div>
      </trac-modal>

      <div class="big-shadow mt-12 mx-24 rounded-md bg-white py-12 px-16">
        <div class="flex items-center justify-between">
          <div
            class="
              mx-auto
              mt-6
              flex
              items-center
              max-w-xl
              w-full
              justify-between
              border-b
            "
          >
            <div
              @click="type = 'myIntegration'"
              :class="{
                'light-blue text-primaryBlue border-primaryBlue border-b':
                  type == 'myIntegration',
              }"
              class="
                text-gray-500
                cursor-pointer
                text-center
                py-3
                w-full
                tracking-wide
              "
            >
              My integrations
            </div>
            <div
              @click="type = 'allIntegration'"
              :class="{
                'light-blue text-primaryBlue border-primaryBlue border-b':
                  type == 'allIntegration',
              }"
              class="
                text-gray-500
                cursor-pointer
                text-center
                w-full
                py-3
                tracking-wide
              "
            >
              All integrations
            </div>
          </div>
          <div
            v-if="type == 'myIntegration'"
            class="flex items-center absolute -mt-10 right-0 mr-20"
          ></div>
        </div>
        <div
          v-if="type == 'myIntegration'"
          class="
            w-full
            mx-auto
            text-center
            pt-12
            pb-12
            text-xs text-gray-700
            tracking-wide
            leading-5
            px-12
          "
        >
          <h3 class="text-lg font-bold text-left mb-10">Accounting</h3>
          <hr class="my-4" />
          <div class="flex">
            <div
              @click="triggerAddIntergration(item)"
              :class="item.active ? 'cursor-pointer' : ''"
              class="
                big-shadow
                rounded-lg
                w-32
                font-bold
                p-6
                flex flex-col
                items-center
                mx-2
              "
              v-for="(item, index) in integrations.myIntegrations.accounting"
              :key="index"
            >
              <div class="rounded-sm flex items-center justify-center h-10">
                <img :src="item.imgUrl" class="w-12" alt="" srcset="" />
              </div>
              <p class="mt-2 text-xs">{{ item.name }}</p>
            </div>
          </div>
        </div>
        <div
          v-else
          class="
            w-full
            mx-auto
            text-center
            pt-12
            pb-12
            text-xs text-gray-700
            tracking-wide
            leading-5
            px-12
          "
        >
          <h3 class="text-lg font-bold text-left">Accounting</h3>
          <hr class="my-4" />
          <div class="flex mb-10">
            <div
              @click="triggerAddIntergration(item)"
              :class="item.active ? 'cursor-pointer' : ''"
              class="
                big-shadow
                rounded-lg
                w-32
                font-bold
                p-6
                flex flex-col
                items-center
                mx-2
              "
              v-for="(item, index) in integrations.allIntegrations.accounting"
              :key="index"
            >
              <div class="rounded-sm flex items-center justify-center h-10">
                <img :src="item.imgUrl" class="w-12" alt="" srcset="" />
              </div>
              <p class="mt-2 text-xs">{{ item.name }}</p>
              <span
                v-if="!item.active"
                class="text-gray-500"
                style="font-size: 10px"
                >(Coming soon)</span
              >
            </div>
          </div>

          <h3 class="text-lg font-bold text-left">Logistics</h3>
          <hr class="my-4" />
          <div class="flex mb-10">
            <div
              @click="triggerAddIntergration(item)"
              :class="item.active ? 'cursor-pointer' : ''"
              class="
                big-shadow
                rounded-lg
                w-32
                font-bold
                p-6
                flex flex-col
                items-center
                mx-2
              "
              v-for="(item, index) in integrations.allIntegrations.logistics"
              :key="index"
            >
              <div class="rounded-sm flex items-center justify-center h-10">
                <img :src="item.imgUrl" class="w-12" alt="" srcset="" />
              </div>
              <p class="mt-2 text-xs">{{ item.name }}</p>
              <span
                v-if="!item.active"
                class="text-gray-500"
                style="font-size: 10px"
                >(Coming soon)</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import {
  DELETE_LOCAL_DB_DATA,
  SAVE_LOCAL_DB_DATA,
} from "../../../browser-db-config/localStorage";
export default {
  data() {
    return {
      type: "myIntegration",
      isLoading: false,
      integrationViewModalCtrl: false,
      selectedStore: null,
      selectedItem: null,
      businessDetails: false,
      allowSetLoyalty: false,
      allowallIntegration: false,
      loyaltyPoints: "",
      defaultLoyalty: 0,
      integrations: null,
    };
  },
  created() {
    DELETE_LOCAL_DB_DATA("selected-integration");

    this.integrations = {
      myIntegrations: {
        accounting: [
          {
            name: "Quickbooks",
            imgUrl: "https://i.ibb.co/MPnKyqW/quickbooks.png",
            active: true,
          },
        ],
      },
      allIntegrations: {
        accounting: [
          {
            name: "Quickbooks",
            imgUrl: "https://i.ibb.co/MPnKyqW/quickbooks.png",
            active: true,
          },
          // {
          //   name: 'SME',
          //   imgUrl: 'https://i.ibb.co/t2Nd5Fj/sme.png',
          //   active: false,
          // },
          {
            name: "Xero",
            imgUrl: "https://i.ibb.co/PNFd1By/xero.png",
            active: false,
          },
        ],
        logistics: [
          {
            name: "Gokada",
            imgUrl: "https://i.ibb.co/27StWw7/gokada.png",
            active: false,
          },
          {
            name: "Kwik",
            imgUrl: "https://i.ibb.co/Fn9xjkL/kwik.png",
            active: false,
          },
        ],
      },
    };
  },
  methods: {
    triggerAddIntergration(item) {
      if (item.active) {
        this.selectedItem = item;
        this.integrationViewModalCtrl = true;
      }
    },
    openIntegrationConnection() {
      SAVE_LOCAL_DB_DATA("selected-integration", this.selectedItem);
      this.$router.push({ name: "IntegrationsConnection" });
    },
  },
};
</script>

<style>
</style>